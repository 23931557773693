export default {
  title: {
    id: 'battery_replacement_bundle_title',
    defaultMessage: 'Battery replacement',
  },
  link: {
    id: 'battery_replacement_bundle_link',
    defaultMessage: 'What BackUp gets you',
  },
  illustrationAlternative: {
    id: 'battery_replacement_bundle_illustration_alternative',
    defaultMessage: 'Back up logo',
  },
  remove: {
    id: 'battery_replacement_bundle_remove',
    defaultMessage: 'Remove',
  },
  modalTitle: {
    id: 'battery_replacement_bundle_offer_modal_title',
    defaultMessage: 'Battery replacement',
  },
  close: {
    id: 'battery_replacement_bundle_modal_close_modal',
    defaultMessage: 'Close',
  },
}
