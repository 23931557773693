<template>
  <RevContentBlock
    v-if="hasNoItems"
    :button-label="i18n(translations.noItemsButton)"
    data-qa="empty-cart-block"
    :image-props="
      {
        alt: '',
        height: 463,
        src: '/img/checkout/emptyBasket.svg',
        style: {
          height: 'auto',
          margin: '0 auto',
          width: 'auto',
        },
        width: 438,
      } as any
    "
    :surtitle="i18n(translations.noItemsSurtitle)"
    :title="i18n(translations.noItemsTitle)"
    :to="resolveLocalizedRoute({ name: HOME })"
  >
    <p class="body-1">
      {{ i18n(translations.noItemsDescription) }}
    </p>
  </RevContentBlock>

  <ClientOnly>
    <div v-if="cartStore.hasAvailableItems">
      <Teleport to="#checkout-side-cart-submit">
        <RevButton
          class="mt-24"
          data-qa="go-to-next-side"
          :disabled="isNextButtonDisabled"
          full-width="always"
          :loading="isNextButtonLoading"
          variant="primary"
          @click="() => nextStep()"
        >
          {{ i18n(translations.buttonCheckout) }}
        </RevButton>
        <div class="caption mt-4 flex items-center justify-center">
          <IconLockLocked class="mr-4 h-16 w-16 self-baseline" />

          {{ i18n(translations.securePayment) }}
        </div>
      </Teleport>
    </div>
  </ClientOnly>

  <div v-if="hasItems">
    <CheckoutNext
      :disabled="isNextButtonDisabled"
      :loading="isNextButtonLoading"
      placement="top"
      @next="nextStep"
    />

    <h1 class="heading-3 mb-12 mt-24 md:mb-24 md:mt-0">
      {{ i18n(translations.mainTitle) }}
    </h1>

    <RevCard
      v-for="item in cartStore.items"
      :id="item.listingId"
      :key="item.listingId"
      class="mb-12 p-24 md:mb-56"
      data-qa="cart"
    >
      <CartProduct
        :data-qa="'cart-product-' + item.listingId"
        data-test="cart-product"
        display-link
        :has-no-stock="!isItemAvailable(item.listingId)"
        :image="item.image"
        :link="getItemLink(item.listingId)"
        :title="item.title"
      >
        <template #default>
          <div v-if="item.gradeName" class="flex">
            <p class="text-static-default-low body-2">
              {{ i18n(translations.productGrade) }}
            </p>
            <p class="text-static-default-low body-2 ml-4">
              {{ getGradeName(item) }}
            </p>
          </div>

          <AccessoriesSummary :accessories="item.providedAccessories" />

          <p v-if="isAccessory(item)" class="text-static-default-low body-2">
            {{ i18n(translations.cartColor) }} {{ item.color }}
          </p>

          <div class="mb-8 flex md:my-8">
            <RevLink class="text-left" @click="onClickWarrantyButton">
              <span class="text-static-default-low body-2-link">{{
                i18n(translations.warranty)
              }}</span>
            </RevLink>
            <ClientOnly>
              <WarrantyModal :name="MODAL_NAMES.CART_WARRANTY" />
            </ClientOnly>
          </div>
          <div
            v-if="isItemAvailable(item.listingId)"
            class="body-1-bold"
            data-qa="price"
          >
            {{ i18n.price(item.price) }}
          </div>
          <div
            v-if="
              isItemAvailable(item.listingId) &&
              shouldDisplayFormerPriceAndYouSave(item) &&
              item.formerPrice
            "
          >
            <div class="mt-4 flex flex-row items-baseline space-x-4">
              <RevTag
                class="caption-bold mt-2 whitespace-nowrap"
                :label="
                  i18n(translations.youSaveDisplay, {
                    reduction: i18n.price(item.formerPrice - item.price),
                  })
                "
                variant="success"
              />
              <button
                class="text-static-default-low caption cursor-text whitespace-nowrap line-through"
              >
                {{
                  i18n(translations.originalPriceDisplay, {
                    price: i18n.price(item.formerPrice),
                  })
                }}
              </button>
            </div>
          </div>

          <div v-if="isItemAvailable(item.listingId) && hasDeliveryPromise">
            <RevDivider class="my-8" />
            <div
              class="text-static-default-low caption-bold whitespace-break-spaces"
            >
              <div
                v-if="
                  item.options[0].choices.some((choice) => choice.price === 0)
                "
                data-test="shipping-promise"
              >
                {{
                  i18n(
                    translations.shippingPromise,
                    getDeliveryDates({
                      choices: item.options[0].choices,
                    }),
                  )
                }}
              </div>

              <div
                v-if="
                  item.options[0].choices.some((choice) => choice.price !== 0)
                "
                data-test="shipping-promise-express"
              >
                {{
                  i18n(
                    translations.shippingPromiseExpress,
                    getDeliveryDates({
                      choices: item.options[0].choices,
                      express: true,
                    }),
                  )
                }}
              </div>
            </div>
          </div>

          <RevTag
            v-if="item.mobilePlan"
            class="mt-4"
            data-qa="mobile-plan-subsidy"
            :label="
              i18n(translations.bundlingDiscount, {
                price: i18n.price(item.mobilePlan.selectedOffer.subsidy),
              })
            "
            variant="primary"
          />
        </template>

        <template #actions>
          <div class="my-20 flex justify-between md:my-0">
            <ArticleQuantitySelector
              v-if="isItemAvailable(item.listingId)"
              :id="`quantity-${item.listingId}`"
              :disabled="hasMobilePlan(item)"
              :limit="10"
              :listing-title="item.title"
              :max="item.quantityMax"
              :model-value="desiredQuantityPerItem[item.listingId] || '1'"
              @update:model-value="
                (value) => handleQuantitySelectorChange(item, value)
              "
            />

            <RevButtonTiny
              class="ml-12"
              data-qa="delete-item-button"
              variant="secondaryDestructive"
              @click="handleDeleteButtonClick(item)"
            >
              {{ i18n(translations.productRemove) }}
            </RevButtonTiny>
          </div>
        </template>
      </CartProduct>

      <BatteryReplacementBundle
        v-if="batteryReplacementInsurance"
        :insurance-offer="batteryReplacementInsurance"
      />

      <Bundle
        v-if="item.mobilePlan && isBouyguesOffer(item.mobilePlan.selectedOffer)"
        class="my-32 md:my-56"
      >
        <BouyguesMobilePlanBundle
          data-qa="mobile-plan"
          data-test="bouygues-mobile-plan"
          :offer="item.mobilePlan.selectedOffer"
          @remove="handleBouyguesMobilePlanRemoval"
        />
      </Bundle>

      <RevDivider
        v-if="
          isItemAvailable(item.listingId) &&
          isInsuranceRevampEnabled &&
          !shouldDisplaySelectedInsuranceOffer(item)
        "
        class="-mx-24 mb-32"
      />

      <section v-if="isItemAvailable(item.listingId)">
        <div
          v-if="shouldDisplayInsurancesOffers(item)"
          :class="[
            'mt-32 md:mt-56',
            {
              'mb-0': hasDeliveryPromise,
              'mb-32 md:mb-56': !hasDeliveryPromise,
            },
          ]"
        >
          <InsurancesOptions
            :ref="(el) => (insurancesRef[item.listingId] = el)"
            can-be-deselected
            data-test="insurances"
            :listing-id="item.listingId"
            :offers="item.insuranceOffers"
            :product-name="item.model"
            show-selected
            tracking-zone="cart"
            @update-compliancy="handleInsuranceOptionsUpdateTerms"
            @update-selected-option="handleInsuranceOptionsUpdate"
          />
          <p
            v-if="errorTermsOfUse[item.listingId]"
            :id="`errorMessage-${item.listingId}`"
            class="text-static-danger-hi mt-3"
          >
            {{ i18n(translations.trustpackCGVError) }}
          </p>
        </div>

        <div
          v-if="shouldDisplaySelectedInsuranceOffer(item)"
          :class="[
            {
              'mb-0': hasDeliveryPromise,
              'mb-32 md:mb-56': !hasDeliveryPromise,
            },
          ]"
        >
          <Bundle v-if="selectedInsurances[item.listingId]" class="my-24">
            <InsuranceSelected
              :error-terms-of-use="errorTermsOfUse[item.listingId]"
              :listing-id="item.listingId"
              :offer="
                selectedInsurances[item.listingId] as CheckoutInsuranceOffer
              "
              @remove="handleInsuranceRemove"
              @update-compliancy="handleInsuranceOptionsUpdateTerms"
            />
          </Bundle>
        </div>

        <div v-if="!hasDeliveryPromise">
          <div
            v-for="option in item.options"
            :key="option.type"
            class="md:mt-56"
          >
            <span class="body-1 mb-24 flex">
              {{ option.title }}
            </span>

            <ShippingOptions
              :listing-id="item.listingId"
              :option="option"
              :show-collection-point-error="hasCollectionPointError"
              @select="selectOption"
            />
          </div>
        </div>
      </section>
    </RevCard>

    <SwapSummary
      v-if="swapStore.offer"
      class="mb-56 mt-32"
      data-qa="swap-summary"
      data-test="swap-summary"
      :details="swapStore.offer.diagnosticSummary"
      :price="swapStore.offer.price"
      :shipping-mode="swapStore.offer.defaultShippingModeId"
      :title="swapStore.offer.title"
      :total-price-after-buyback="cartStore.totalPriceAfterBuyback"
      @delete-swap="handleDeleteSwap"
    />

    <RevCard v-if="swapStore.hasAdvertisement" class="mb-56 mt-32 md:hidden">
      <SwapBlock
        compact
        :grade-id="0"
        :modal-name="BUYBACK_MODAL_NAMES.SWAP_CART"
        :model="swapAdvertisement.model"
        :price="swapAdvertisement.price"
        product-category=""
        shipping=""
        :swap-offer="swapAdvertisement as Estimation"
        swap-redirection=""
        :swap-status="SWAP_SUCCESS"
        tracking-label="swap_from_cart"
        @confirmation="handleSwapConfirmation"
      />
    </RevCard>

    <div>
      <RevDivider v-if="isCrossSellVisible" class="my-40 md:my-56" />

      <RecommendationCarousel
        :options="{
          withCrossedPrice: true,
          withStartingFrom: false,
          withGrade: true,
          withAddToCart: true,
        }"
        :recommendation-query="{
          category: 'crossSellWeb',
          limit: 12,
          personalisation: true,
          scope: 'cart',
          scopeId: recommendationIds,
        }"
        :title="i18n(translations.titleCrossSell)"
        :trackingData="{
          list: 'cross sell',
        }"
        @loaded="handleCrossSellLoaded"
        @refresh="handleCrossSellRefresh"
      />

      <RevDivider v-if="isCrossSellVisible" class="my-40 md:my-56" />
    </div>

    <ReassuranceItems>
      <BouyguesReassuranceItems
        v-if="cartStore.bouyguesMobilePlan"
        :benefits="cartStore.bouyguesMobilePlan.benefits"
      />
    </ReassuranceItems>

    <CheckoutNext
      :disabled="isNextButtonDisabled"
      :loading="isNextButtonLoading"
      placement="bottom"
      @next="nextStep"
    />

    <InsuranceCatchupModal
      v-if="shouldDisplayCatchupModal"
      :id="firstItemAvailableWithInsurances.listingId"
      class="z-10"
      :model="firstItemAvailableWithInsurances.model"
      :offers="firstItemAvailableWithInsurances.insuranceOffers"
      @continue="handleCatchupModalClose"
      @go-to="handleCatchupModalGoTo"
    />

    <BatteryReplacementModal
      v-if="batteryReplacementInsurance"
      :device-name="firstItemAvailableWithInsurances.model"
      :insurance-offer="batteryReplacementInsurance"
      @accept="
        handleBatteryReplacementAccept({
          id: firstItemAvailableWithInsurances.listingId,
          insurance: batteryReplacementInsurance,
        })
      "
      @ignore="handleBatteryReplacementIgnore"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs, useRoute, useRouter, useRuntimeConfig } from '#imports'
import {
  type ComponentPublicInstance,
  type ComputedRef,
  computed,
  onMounted,
  ref,
  watch,
} from 'vue'

import { type InsuranceOffer, MarketCountryCode } from '@backmarket/http-api'
import {
  deleteService,
  deleteSwap,
  postAcceptAgreement,
  postUpdateInsuranceOffer,
  postUpdateOption,
  postUpdateQuantity,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import type {
  CartItem,
  CheckoutInsuranceOffer,
  ShippingChoice,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useAuthStore } from '@backmarket/nuxt-layer-oauth/useAuthStore'
import RecommendationCarousel from '@backmarket/nuxt-layer-recommendation/RecommendationCarousel.vue'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useDebounceFn } from '@backmarket/utils/composables/useDebouncedFn'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevCard } from '@ds/components/Card'
import { RevContentBlock } from '@ds/components/ContentBlock'
import { RevDivider } from '@ds/components/Divider'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'
import { RevTag } from '@ds/components/Tag'
import { IconLockLocked } from '@ds/icons/IconLockLocked'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import type { Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'
import { MODAL_NAMES as BUYBACK_MODAL_NAMES } from '~/scopes/buyback/constants'
import { SWAP_SUCCESS } from '~/scopes/buyback/swap/components/SwapBlock/SwapBlock.constants'
import SwapBlock from '~/scopes/buyback/swap/components/SwapBlock/SwapBlock.vue'
import SwapSummary from '~/scopes/buyback/swap/components/SwapSummary/SwapSummary.vue'
import { isBouyguesOffer } from '~/scopes/checkout/utils/isBouyguesOffer'
import { HOME } from '~/scopes/home/route-names'
import { MODAL_NAMES as PRODUCT_MODAL_NAMES } from '~/scopes/product/constants'
import ReassuranceItems from '~/scopes/reassurance/components/ReassuranceItems/ReassuranceItems.vue'
import WarrantyModal from '~/scopes/reassurance/components/WarrantyModal/WarrantyModal.vue'

import AccessoriesSummary from '../../components/AccessoriesSummary.vue'
import ArticleQuantitySelector from '../../components/ArticleQuantitySelector.vue'
import BouyguesReassuranceItems from '../../components/BouyguesReassuranceItems/BouyguesReassuranceItems.vue'
import CartProduct from '../../components/CartProduct.vue'
import CheckoutNext from '../../components/CheckoutNext.vue'
import InsuranceCatchupModal from '../../components/InsuranceCatchupModal.vue'
import InsuranceSelected from '../../components/InsurancesOptions/InsuranceSelected/InsuranceSelected.vue'
import InsurancesOptions from '../../components/InsurancesOptions/InsurancesOptions.vue'
import ShippingOptions from '../../components/ShippingOptions.vue'
import useHandleUnauthorizedUser from '../../composables/useHandleUnauthorizedUser'
import { DISPLAY_CATCHUP_MODAL_FOR_COUNTRY } from '../../config/InsuranceCatchupModal'
import { DEBOUNCE_DELAY, MODAL_NAMES } from '../../config/constants'
import { CHECKOUT } from '../../routes-names'
import { useCartStore } from '../../stores/cartStore'
import { useLoaderStore } from '../../stores/loaderStore'
import { useSwapStore } from '../../stores/swapStore'

import translations from './Cart.translations'
import BatteryReplacementBundle from './components/BatteryReplacementBundle/BatteryReplacementBundle.vue'
import BatteryReplacementModal from './components/BatteryReplacementModal/BatteryReplacementModal.vue'
import BouyguesMobilePlanBundle from './components/BouyguesMobilePlanBundle/BouyguesMobilePlanBundle.vue'
import Bundle from './components/Bundle/Bundle.vue'

const { openErrorToast, openSuccessToast } = useTheToast()

const experiments = useExperiments()
const authStore = useAuthStore()
const cartStore = useCartStore()
const loaderStore = useLoaderStore()
const swapStore = useSwapStore()
const runtimeConfig = useRuntimeConfig()
const tracking = useTracking()
const router = useRouter()

const i18n = useI18n()
const route = useRoute()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const {
  market: { countryCode },
} = useMarketplace()

const { handleUnauthorizedUser } = useHandleUnauthorizedUser()

const { availableItems, isItemAvailable, getItemLink } = storeToRefs(cartStore)

const desiredQuantityPerItem = ref(
  availableItems.value.reduce<Record<string, string>>(
    (acc, { listingId, quantity }) => ({
      ...acc,
      [listingId]: String(quantity),
    }),
    {},
  ),
)

const selectedInsurances = ref(
  cartStore.items
    .filter((item) => item.quantity === 1 && item.insuranceOffers?.length > 0)
    .reduce<Record<string, CheckoutInsuranceOffer | undefined>>(
      (acc, { listingId, insuranceOffers }) => ({
        ...acc,
        [listingId]: insuranceOffers.find((offer) => offer.selected),
      }),
      {},
    ),
)

const productWithSelectedInsurance = ref(
  Object.entries(selectedInsurances.value).reduce<
    Record<string, CheckoutInsuranceOffer | undefined>
  >(
    (acc, [id, insurance]) =>
      !insurance?.defaultOffer ? { ...acc, [id]: insurance } : acc,
    {},
  ),
)
const hasSubmittedOnce = ref(false)
const errorTermsOfUse = ref<Record<string, boolean>>({})
const insurancesRef = ref<
  Record<string, Element | ComponentPublicInstance | null>
>({})
const isCrossSellVisible = ref(false)
const hasCollectionPointError = ref(false)

const hasNoItems = computed(() => isEmpty(cartStore.items))
const hasItems = computed(() => !isEmpty(cartStore.items))

const isInsuranceRevampEnabled = computed(() => {
  return experiments['experiment.insuranceCartRevamp'] === 'newInsuranceCart'
})

const isMonthlyInsuranceEnabled = computed(
  () =>
    experiments['experiment.insuranceMonthlySubscription'] ===
    'insuranceMonthlyEnabled',
)

const hasDeliveryPromise = computed(
  () =>
    experiments['experiment.deliveryMethodPosition'] ===
    'shippingDeliveryMethod',
)

const isNextButtonLoading = computed(
  () =>
    !availableItems.value.every(
      (item) =>
        parseInt(desiredQuantityPerItem.value[item.listingId], 10) ===
        item.quantity,
    ),
)
const isNextButtonDisabled = computed(
  () => !cartStore.hasAvailableItems || isNextButtonLoading.value,
)

const hasDefaultInsuranceSelected = computed(() => {
  const itemsWithDefaultInsurances = availableItems.value.reduce<CartItem[]>(
    (acc, item) => {
      if (
        item.insuranceOffers?.length > 1 &&
        item.quantity === 1 &&
        item.insuranceOffers.some(
          (offer) => offer.selected && offer.defaultOffer,
        )
      ) {
        return [...acc, item]
      }

      return acc
    },
    [],
  )

  return itemsWithDefaultInsurances.length === 1
})

const firstItemAvailableWithInsurances: ComputedRef<
  CartItem | Record<string, never>
> = computed(() => {
  return (
    availableItems.value.find(
      (item) =>
        item.insuranceOffers?.length > 1 &&
        item.quantity === 1 &&
        item.insuranceOffers.some(
          (offer) => offer.selected && offer.defaultOffer,
        ),
    ) || {}
  )
})

const batteryReplacementInsurance = computed(() => {
  if (!isEmpty(firstItemAvailableWithInsurances.value)) {
    return firstItemAvailableWithInsurances.value.insuranceOffers.find(
      (offer) => offer.coverageKinds.includes('BATTERY_REPLACEMENT'),
    )
  }

  return null
})

const displayCatchUpModalForCountry =
  DISPLAY_CATCHUP_MODAL_FOR_COUNTRY[countryCode] ??
  DISPLAY_CATCHUP_MODAL_FOR_COUNTRY.default

const shouldDisplayCatchupModal = computed(
  () =>
    !runtimeConfig.public.KILL_INSURANCE_CATCHUP_MODAL &&
    displayCatchUpModalForCountry &&
    cartStore.showCatchupModal &&
    hasDefaultInsuranceSelected.value &&
    !isEmpty(firstItemAvailableWithInsurances.value),
)

const swapAdvertisement = computed(() => ({
  model:
    availableItems.value.length === 1
      ? availableItems.value[0].model
      : i18n(translations.swapMultipleItemsTitle),
  price: i18n.price(swapStore.advertisement?.cartPriceWithSwapDiscount ?? ''),
  hasOffer: swapStore.hasAdvertisement,
}))

const recommendationIds = computed(() =>
  cartStore.items.reduce((acc, item) => `${acc},${item.listingId}`, ''),
)

function trackFunnel() {
  if (typeof route.name === 'string') {
    tracking.trackFunnel(cartStore.trackingData(route.name))
  }
}

onMounted(async () => {
  trackFunnel()
})

const firstUncheckedCompliancyListingId = () => {
  return Object.keys(selectedInsurances.value).find((listingId) => {
    const insurance = selectedInsurances.value[listingId]

    return (
      insurance?.compliancy?.isUserAgreementRequired &&
      !insurance?.compliancy?.isUserAgreementAccepted
    )
  })
}

const hasBatteryReplacement = (offers: InsuranceOffer[]) => {
  return offers.some((offer) => {
    return offer.coverageKinds.includes('BATTERY_REPLACEMENT')
  })
}

const nextStep = async (options?: { skipCatchupModal: boolean }) => {
  tracking.trackClick({
    name: 'go_checkout',
    zone: 'cart',
  })

  hasSubmittedOnce.value = true

  // Check if collection point option has a selected collection point
  if (
    cartStore.isSelectedCollectionPointMissing &&
    cartStore.optionWithMissingCollectionPointSelected
  ) {
    hasCollectionPointError.value = true
    const firstOption = cartStore.optionWithMissingCollectionPointSelected

    const element = document.getElementById(
      `cart-shipping-option-${firstOption[0]}-${firstOption[1][0]?.choice?.shippingId}`,
    )
    element?.scrollIntoView({ block: 'center', behavior: 'smooth' })

    return
  }

  const listingId = firstUncheckedCompliancyListingId()

  // if position is defined then error
  if (listingId) {
    errorTermsOfUse.value[listingId] = true
    // scroll to the first article where the error is
    const element = document.getElementById(`compliancy-${listingId}`)
    element?.scrollIntoView({ block: 'start', behavior: 'smooth' })

    // block nextStep if terms of use are not ok
    return
  }

  if (
    !options?.skipCatchupModal &&
    shouldDisplayCatchupModal.value &&
    hasBatteryReplacement(
      firstItemAvailableWithInsurances.value.insuranceOffers,
    )
  ) {
    openModal(PRODUCT_MODAL_NAMES.BATTERY_REPLACEMENT)
    // block nextStep if modal is opened

    return
  }
  if (
    !options?.skipCatchupModal &&
    shouldDisplayCatchupModal.value &&
    !hasBatteryReplacement(
      firstItemAvailableWithInsurances.value.insuranceOffers,
    )
  ) {
    openModal(MODAL_NAMES.CART_INSURANCE_OFFER_CATCHUP)

    // block nextStep if modal is opened
    return
  }

  try {
    if (authStore.authenticated) {
      // check auth state and proceed
      loaderStore.enable()
      await cartStore.fetchShippings()

      router.push({
        name: cartStore.isShippable
          ? CHECKOUT.ADDRESS_CONFIRMATION
          : CHECKOUT.SHIPPING_ADDRESS,
      })
    } else {
      router.push({
        name: CHECKOUT.AUTHENTICATION,
      })
    }
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[Checkout] Unhandled error going to the next step',
    )
  } finally {
    loaderStore.disable()
  }
}

const getGradeName = (item: CartItem) => {
  if (item.gradeExtended.hasNewBattery) {
    return i18n(translations.productGradeWithNewBattery, {
      grade: item.gradeName,
    })
  }

  return item.gradeName
}

// TODO [PI-1677] Add isAccessory (boolean) to Cart API
const isAccessory = (item: CartItem) => {
  return i18n(translations.crossSellCategory) === item.category
}

const onClickWarrantyButton = () => {
  openModal(MODAL_NAMES.CART_WARRANTY)
}

const updateItem = async (item: CartItem, action: 'delete' | 'update') => {
  try {
    await $httpFetch(postUpdateQuantity, {
      body: {
        action,
        listingId: item.listingId,
        newQuantity: item.quantity,
        listingPrice: item.price,
      },
    })
    if (action === 'delete') {
      const product = cartStore
        .trackingData(route.name as string)
        .products.find(
          (trackingProduct) => item.productId === trackingProduct.id,
        )

      if (product) {
        tracking.trackRemoveFromCart({
          product,
        })
      }
    }

    await cartStore.fetchCart()

    trackFunnel()
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error updating quantity',
    )
  }
}

const deleteInsuranceSelected = (item: CartItem) => {
  delete selectedInsurances.value[item.listingId]
  delete productWithSelectedInsurance.value[item.listingId]
}

const handleDeleteButtonClick = async (item: CartItem) => {
  await updateItem(item, 'delete')
  delete selectedInsurances.value[item.listingId]
  delete desiredQuantityPerItem.value[item.listingId]
  deleteInsuranceSelected(item)
}

const handleQuantitySelectorChange = useDebounceFn(
  async function doUpdateQuantity(item, quantity) {
    desiredQuantityPerItem.value[item.listingId] = quantity
    await updateItem({ ...item, quantity }, 'update')

    // Since the InsuranceSelected component does not consume the API data we need to sync the local value
    if (quantity !== 1) delete selectedInsurances.value[item.listingId]
  },
  DEBOUNCE_DELAY,
)

const shouldDisplaySelectedInsuranceOffer = (item: CartItem) => {
  const insurance = selectedInsurances.value[item.listingId]

  return (
    isInsuranceRevampEnabled.value &&
    !isEmpty(item.insuranceOffers) &&
    !isEmpty(insurance) &&
    !insurance.defaultOffer
  )
}

const shouldDisplayInsurancesOffers = (item: CartItem) => {
  return (
    !isEmpty(item.insuranceOffers) && !shouldDisplaySelectedInsuranceOffer(item)
  )
}

const handleInsuranceOptionsUpdate = async ({
  id,
  insurance,
}: {
  id: string
  insurance: CheckoutInsuranceOffer
}) => {
  errorTermsOfUse.value[id] = false
  selectedInsurances.value[id] = insurance

  try {
    await $httpFetch(postUpdateInsuranceOffer, {
      body: {
        listingId: id,
        insuranceOfferId: insurance.id,
      },
      queryParams: {
        monthlyInsuranceSupported: isMonthlyInsuranceEnabled.value,
      },
    })

    await cartStore.fetchCart()

    trackFunnel()
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error updating insurance offer',
    )
  }
}

const handleInsuranceRemove = async ({ id }: { id: string }) => {
  const selectedInsurance = selectedInsurances.value[id]

  if (!selectedInsurance) return

  tracking.trackClick({
    name: 'delete_insurance',
    zone: 'cart',
    value: selectedInsurance.title,
  })

  delete errorTermsOfUse.value[id]
  delete selectedInsurances.value[id]

  try {
    await $httpFetch(postUpdateInsuranceOffer, {
      body: {
        listingId: id,
        insuranceOfferId: 0,
      },
    })

    await cartStore.fetchCart()

    trackFunnel()
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error updating insurance offer',
    )
  }
}

async function handleBouyguesMobilePlanRemoval(offerId: string) {
  try {
    await $httpFetch(deleteService, { body: { offerId } })
    await cartStore.fetchCart()

    trackFunnel()

    openSuccessToast({
      title: i18n(translations.mobilePlanRemovalSuccessfulTitle),
      content: i18n(translations.mobilePlanRemovalSuccessfulContent),
    })
  } catch {
    openErrorToast()
  }
}

const handleInsuranceOptionsUpdateTerms = async ({
  id,
  insurance,
}: {
  id: string
  insurance: CheckoutInsuranceOffer
}) => {
  errorTermsOfUse.value[id] = false
  selectedInsurances.value[id] = insurance

  try {
    if (!isInsuranceRevampEnabled.value) {
      await $httpFetch(postAcceptAgreement, {
        body: {
          listingId: id,
          insuranceOfferId: insurance.id,
        },
        queryParams: {
          monthlyInsuranceSupported: isMonthlyInsuranceEnabled.value,
        },
      })
    }

    await cartStore.fetchCart()
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error updating terms and conditions',
    )
  }
}

const handleCatchupModalClose = () => {
  nextStep({ skipCatchupModal: true })
}

const handleBatteryReplacementAccept = ({
  id,
  insurance,
}: {
  id: string
  insurance: CheckoutInsuranceOffer
}) => {
  cartStore.skipCatchupModal()
  handleInsuranceOptionsUpdate({
    id,
    insurance,
  })
}

const handleBatteryReplacementIgnore = () => {
  cartStore.skipCatchupModal()
  handleCatchupModalClose()
}

const handleCatchupModalGoTo = async ({ id }: { id: string }) => {
  // To fix
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  insurancesRef.value[id]?.[0]?.$el?.scrollIntoView({
    block: 'center',
    behavior: 'smooth',
  })
}

const selectOption = async (
  body: {
    listingId: string
    optionType: string
    optionId: string
  },
  choice: ShippingChoice,
) => {
  hasCollectionPointError.value = false

  try {
    await $httpFetch(postUpdateOption, {
      body,
    })

    tracking.trackClick({
      zone: 'cart',
      name: choice.shipperDisplay || 'not-available',
    })

    await cartStore.fetchCart()
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error updating shipping option',
    )
  }
}

const handleSwapConfirmation = async () => {
  await cartStore.fetchCart()
}

const handleDeleteSwap = async () => {
  try {
    await $httpFetch(deleteSwap)
    const eventName = 'delete_swap'

    tracking.trackSwap({
      label: eventName,
      action: 'funnel > Step 4',
      ...(swapStore.offer && swapStore.offer.diagnosticPayload),
    })

    tracking.trackClick({
      name: eventName,
      value: { ...(swapStore.offer && swapStore.offer.diagnosticPayload) },
      zone: 'swap',
    })

    await cartStore.fetchCart()
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error deleting swap',
    )
  }
}

const handleCrossSellLoaded = (results: Product[]) => {
  if (isEmpty(results)) {
    isCrossSellVisible.value = false
  } else {
    isCrossSellVisible.value = true
  }
}

const handleCrossSellRefresh = async () => {
  await cartStore.fetchCart()
}

const hasMobilePlan = (item: CartItem) => {
  return item.mobilePlan !== null
}

const shouldDisplayFormerPriceAndYouSave = (item: CartItem) => {
  return (
    ![MarketCountryCode.JP].includes(countryCode) &&
    item.marketplaceCategoryId === 2
  )
}

const getDeliveryDates = ({
  choices,
  express = false,
}: {
  choices: ShippingChoice[]
  express?: boolean
}) => {
  let earliest: Date | undefined
  let latest: Date | undefined
  let amount = ''

  choices
    .filter((choice) => (express ? choice.price !== 0 : choice.price === 0))
    .forEach((choice) => {
      if (choice.earliestArrivalDate) {
        const newDate = new Date(choice.earliestArrivalDate)
        earliest = earliest ?? newDate
        earliest = newDate < earliest ? newDate : earliest
      }

      if (choice.latestArrivalDate) {
        const newDate = new Date(choice.latestArrivalDate)
        latest = latest ?? newDate

        if (newDate >= latest) {
          latest = newDate
          amount = choice.priceWithCurrency
        }
      }
    })

  return {
    earliest: earliest
      ? i18n.date(earliest, { month: 'short', day: 'numeric' })
      : '',
    latest: latest ? i18n.date(latest, { month: 'short', day: 'numeric' }) : '',
    amount,
  }
}

watch(cartStore.items, (newItems) => {
  selectedInsurances.value = newItems
    .filter((item) => item.quantity === 1 && item.insuranceOffers?.length > 0)
    .reduce<Record<string, CheckoutInsuranceOffer | undefined>>(
      (acc, { listingId, insuranceOffers }) => ({
        ...acc,
        [listingId]: insuranceOffers.find((offer) => offer.selected),
      }),
      {},
    )
})

watch(
  () => availableItems.value,
  (newItems) => {
    desiredQuantityPerItem.value = newItems.reduce<Record<string, string>>(
      (acc, { listingId, quantity }) => ({
        ...acc,
        [listingId]: String(quantity),
      }),
      {},
    )
  },
)
</script>
